import React from 'react';
import {ComponentTitle,ErrorInfoComponent,InfoText, LinkBox,PrepareFormElement,PrepareFormElements} from "../util/helper";
import {trackCtaEvent, trackSignUpCtaEvent} from "../../utils/Utils";

export const LoginView = (props) => {

    const {blockData} = props;

    return (
        <>
            {
                blockData &&
                <>
                    <ComponentTitle text={"Login"}/>
                    {blockData.loginFailed && <ErrorInfoComponent text={"Incorrect email or password"}/>}
                    {blockData.emailDomainError && <ErrorInfoComponent text={"Type in your Qualpass username only"}/>}

                    <PrepareFormElements fields={blockData.fields} isEditPage={true}/>

                    <PrepareFormElement field={blockData.formControl}
                                        isEditPage={true}
                                        index={blockData.fields.length}/>

                    <InfoText as="p">

                        <LinkBox width={0.55} to={{pathname: `${process.env.REACT_APP_RESET_PASSWORD_OKTA}`}} target="_blank" onClick={() => {
                            trackCtaEvent('/forgotPassword', 'Reset your password')
                        }}> {'Reset your password'}</LinkBox>
                    </InfoText>

                    <InfoText as="p">Don't have a Qualcomm ID?

                        <LinkBox data-testid='signup' width={0.55} to={{pathname: `${process.env.REACT_APP_REGISTRATION_OKTA}`}} target="_blank" onClick={trackSignUpCtaEvent}>{'Sign up here.'} </LinkBox>
                    </InfoText>

                    <input type="hidden" name="TARGET" value="$$target$$"/>
                    <input type="hidden" name="USER" value=""/>
                    <input type="hidden" name="smagentname" value="$$smagentname$$"/>
                    <input type="hidden" name="SMENC" value="ISO-8859-1"/>
                    <input type="hidden" name="SMLOCALE" value="US-EN"/>
                    <input type="hidden" name="PASSWORD" value=""/>
                </>
            }
        </>
    )
};

