import React, {useEffect, useState} from "react";
import {ErrorMessage, Field} from "formik";
import {WrappedFormComponents} from "../../util/FormComponents/FormikWrappedComponents";
import {
    defaultLabelFont,
    emptyCheck,
    ProfileDataRowFlex,
    StyledCompanyChangeHeaderText, StyledCompanyChangeList, StyledCompanyChangeText,
    StyledText,
    TextRow
} from "../ProfileControls";
import {Box} from "@qc-modules/components";
import {defaultValueFont} from "../../util/helper";
import {ValidationFunctions} from "../../util/validation/ValidationFunctions";
import {MessageWrapper} from "../../util/validation/ValidationStyles";
import {RequiredTextRow} from '../HelperComponents';

export const CompanyInfoFormFields = ({...props}) => {
    const {isEditMode, isFormSubmitted, isOrgChange, setIsOrgChange} = props;
    const [doesCountryHasStates, setCountryHasStates] = useState(false);
    const [countryStates, setCountryStates] = useState([]);

    useEffect(() => {
        const matchedCountry = props.countryStateMap.find(obj => obj.id === props.values.country);
        setCountryHasStates(matchedCountry && matchedCountry.states.length);
        setCountryStates((matchedCountry && matchedCountry.states) || []);
    }, [props.values.country, isEditMode, props.countryStateMap]);

    return (
        <>
            <ProfileDataRowFlex>
                <Box width={0.45}>
                    <TextRow align={'left'} {...defaultLabelFont}
                    >Company or university</TextRow>
                </Box>
                <Box direction={'column'} align={'baseline'} width={0.55}>
                    {isEditMode && (props.isMultiDomainUser) && !props.isStreamlinedUser ? <Field
                            as="select"
                            name="orgExportId"
                            id="orgExportId"
                            placeholder="Company or university"
                            type="text"
                            width={1}
                            options={props.domainOrgs}
                            component={WrappedFormComponents.SelectWrapper}
                            onChange={(e) => {
                                if (e.currentTarget.value !== props.values.companyOrUniversity) {
                                    setIsOrgChange(true);
                                } else {
                                    setIsOrgChange(false);
                                }
                                props.handleChange(e);
                            }}
                        /> :

                        <StyledText data-testid={`companyOrUniversity-label`} {...defaultValueFont}>
                            {!props.isLightUser ? emptyCheck(props.values.companyNameToDisplay) : emptyCheck(null)}
                        </StyledText>}
                </Box>
            </ProfileDataRowFlex>
            {isOrgChange && isEditMode &&
            <ProfileDataRowFlex>
                <Box width={0.45}>
                    <TextRow align={'left'} {...defaultLabelFont}
                    />
                </Box>
                <Box direction={'column'} align={'baseline'} width={0.55}>
                    <StyledCompanyChangeHeaderText> Changing your company will result in multiple changes to your
                        account including: </StyledCompanyChangeHeaderText>
                    <StyledCompanyChangeList>
                        <StyledCompanyChangeText> You will be logged out and you will need to log in
                            again </StyledCompanyChangeText>
                        <StyledCompanyChangeText> You will need to acknowledge the Product Kit License Agreement
                            again </StyledCompanyChangeText>
                        <StyledCompanyChangeText> You may no longer have access to the same downloadable assets, support
                            Cases or Projects </StyledCompanyChangeText>
                        <StyledCompanyChangeText> You may get impacted with Sales Center test tools access and may no
                            longer have access to the same Software Tools,
                            Development Boards/Kits and Bluetooth Licenses. </StyledCompanyChangeText>
                    </StyledCompanyChangeList>
                </Box>
            </ProfileDataRowFlex>}
            <ProfileDataRowFlex>
                <Box width={0.45}>
                    <TextRow align={'left'} {...defaultLabelFont}
                    >Street Address 1</TextRow>
                </Box>
                <Box direction={'column'} align={'baseline'} width={0.55}>


                    {isEditMode ? (<><Field name="addressLine1"
                                            id="addressLine1"
                                            placeholder="Street address 1"
                                            component={WrappedFormComponents.InputWrapper}
                                            type="text"
                                            width={1}
                                            validate={ValidationFunctions.validateAddressLineOne}

                        />

                            {isFormSubmitted && <MessageWrapper><ErrorMessage name="addressLine1"/></MessageWrapper>}
                        </>) :
                        <StyledText data-testid={`addressLine1-label`} {...defaultValueFont}>
                            {emptyCheck(props.values.addressLine1)}
                        </StyledText>}
                </Box>
            </ProfileDataRowFlex>
            <ProfileDataRowFlex>
                <Box width={0.45}>
                    <TextRow align={'left'} {...defaultLabelFont}
                    >Street Address 2</TextRow>
                </Box>
                <Box direction={'column'} align={'baseline'} width={0.55}>


                    {isEditMode ? (<> <Field name="addressLine2"
                                             id="addressLine2"
                                             placeholder="Street address 2"
                                             component={WrappedFormComponents.InputWrapper}
                                             type="text"
                                             width={1}
                                             validate={ValidationFunctions.validateAddressLineTwo}
                            />
                                {isFormSubmitted && <MessageWrapper><ErrorMessage name="addressLine2"/></MessageWrapper>}
                            </>
                        ) :
                        <StyledText data-testid={`addressLine2-label`} {...defaultValueFont}>
                            {emptyCheck(props.values.addressLine2)}
                        </StyledText>}
                </Box>
            </ProfileDataRowFlex>
            <ProfileDataRowFlex>
                <Box width={0.45}>
                    <RequiredTextRow required align={'left'} {...defaultLabelFont}
                    >Country or Location</RequiredTextRow>
                </Box>
                <Box direction={'column'} align={'baseline'} width={0.55}>
                    {isEditMode ? (<><Field name="country"
                                            id="country"
                                            placeholder="Country"
                                            component={WrappedFormComponents.SelectWrapper}
                                            options={props.countries}
                                            type="text"
                                            width={1}
                                            validate={ValidationFunctions.validateCountry}
                                            onChange={(e) => {
                                                props.handleChange(e)
                                            }}

                            />
                                {isFormSubmitted && <MessageWrapper><ErrorMessage name="country"/></MessageWrapper>}
                            </>
                        ) :
                        <StyledText data-testid={`country-label`} {...defaultValueFont}>
                            {emptyCheck(props.values.countryToDisplay)}
                        </StyledText>}
                </Box>
            </ProfileDataRowFlex>

            <ProfileDataRowFlex>
                <Box width={0.45}>
                    <TextRow align={'left'} {...defaultLabelFont}
                    >City</TextRow>
                </Box>
                <Box direction={'column'} align={'baseline'} width={0.55}>
                    {isEditMode ? (<><Field name="city"
                                            id="city"
                                            placeholder="City"
                                            component={WrappedFormComponents.InputWrapper}
                                            type="text"
                                            width={1}
                                            validate={ValidationFunctions.validateCity}
                            />

                                {isFormSubmitted &&
                                <MessageWrapper><ErrorMessage name="city"/></MessageWrapper>}
                            </>
                        ) :
                        <StyledText data-testid={`city-label`} {...defaultValueFont}>
                            {emptyCheck(props.values.city)}
                        </StyledText>}
                </Box>
            </ProfileDataRowFlex>

            {doesCountryHasStates ? (<ProfileDataRowFlex>
                <Box width={0.45}>
                    <TextRow align={'left'} {...defaultLabelFont}
                    >State/Province/Region </TextRow>
                </Box>
                <Box direction={'column'} align={'baseline'} width={0.55}>
                    {isEditMode ? (

                            <>  <Field name="state"
                                       id="state"
                                       placeholder="State / province / region"
                                       options={countryStates}
                                       component={WrappedFormComponents.SelectWrapper}
                                       type="text"
                                       width={1}
                                       validate={ValidationFunctions.validateUsState}
                            />
                                {isFormSubmitted && <MessageWrapper><ErrorMessage
                                    name="state"/></MessageWrapper>}
                            </>

                        ) :
                        <StyledText
                            data-testid={`state-label`} {...defaultValueFont}>
                            {emptyCheck(props.values.stateToDisplay)}
                        </StyledText>}
                </Box>
            </ProfileDataRowFlex>) : <></>}

            <ProfileDataRowFlex>
                <Box width={0.45}>
                    <TextRow align={'left'} {...defaultLabelFont}
                    >Postal Code</TextRow>
                </Box>
                <Box direction={'column'} align={'baseline'}
                     width={0.55}>
                    {isEditMode ? (<><Field name="postalCode"
                                            id="postalCode"
                                            placeholder="Postal code"
                                            component={WrappedFormComponents.InputWrapper}
                                            type="text"
                                            width={1}
                                            validate={ValidationFunctions.validatePostalCode}
                            />
                                {isFormSubmitted && <MessageWrapper><ErrorMessage
                                    name="postalCode"/></MessageWrapper>}
                            </>

                        )
                        :
                        <StyledText
                            data-testid={`postalCode-label`} {...defaultValueFont}>
                            {emptyCheck(props.values.postalCode)}
                        </StyledText>}
                </Box>
            </ProfileDataRowFlex>

        </>
    )
};
