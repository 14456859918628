import {Form, withFormik} from "formik";
import React, {useContext, useEffect} from "react";
import {ColoredLine, ErrorText, PanelHeader} from '../HelperComponents';
import PersonalInfoFormFields from "./PersonalInfoFormFields";
import {checkTokenValidity, checkAcrValues} from "../../../services/authService";
import {profileConstants} from "../../util/profileConstants2";
import {trackCtaEvent} from "../../../utils/Utils";
import AppContext from "../../../context/AppContext";
import {isValidImpersonation} from "../../util/helper";
import useProfile from "../../../hooks/useProfile2";


const PersonalInfoBasicForm = ({...props}) => {
    const profileStore = useProfile();
    const userData = profileStore.userData;
    const {appStore} = useContext(AppContext);
    const impersonationStatus = appStore.impersonationStatus
    const previousPage = '/login';

    useEffect(() => {
        if (props.status.isEditMode && userData !== null && userData !== undefined) {
            let visitor = {
                'country_code': userData.country_code,
                'org_id': userData.org.orgId,
                'org_name': userData.org.name,
                'qcguid': userData.qcGuid,
                'user_name': userData.username,
                'loggedIn': "logged_in",
                'user_domain': userData.username && userData.username.indexOf("@") > -1 ? userData.username.slice(userData.username.indexOf("@") + 1) : 'qualcomm.com'
            }
            if (isValidImpersonation(impersonationStatus)) {
                visitor['impersonating'] = true
                visitor['impersonator'] = impersonationStatus.impersonator
            }

            // trackPageLoadEvent('profile', 'PersonalInfo', previousPage, visitor, {})
            window.pageChange('profile', 'PersonalInfo', previousPage, visitor, {})
        }

    }, [props.status.isEditMode, userData, impersonationStatus]);

    return (
        <>
            <Form onSubmit={props.handleSubmit} className="Bizible-Exclude">
                <PanelHeader
                    name={"personalInfo"}
                    isLaptop={props.isLaptop}
                    label={'Personal information'}
                    isEdit={props.status.isEditMode}
                    disableEdit={props.disableEdit}
                    hideControls={false}
                    handleEditButton={() => {
                        checkAcrValues();
                        checkTokenValidity();
                        props.setStatus({isEditMode: true});
                        props.toggleOtherSectionsEditBtn();
                        trackCtaEvent('PersonalInfo', 'Edit')
                    }}
                    handleCancelButton={() => {
                        props.toggleOtherSectionsEditBtn();
                        props.resetForm();
                        props.setStatus({isEditMode: false});
                        trackCtaEvent('PersonalInfo', 'Cancel')
                    }}/>


                {/*API Error Messages*/}
                {props.status && props.status.isEditMode && props.status.isError && props.status.errorMsg &&
                    props.status.errorMsg.map((err, key) => (
                        <ErrorText key={key} w={100}>{err}</ErrorText>)
                    )}

                <ColoredLine/>
                {<PersonalInfoFormFields isFormSubmitted={props.submitCount > 0} {...props}
                                         isEditMode={props.status.isEditMode}/>}
            </Form>
        </>
    )
};

export const PersonalInfoForm = withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({formValues}) => {
        return formValues;
    },
    mapPropsToStatus: ({isLaptop, isInternalUser}) => {
        if (isInternalUser) {
            return {isEditMode: false}
        }
        return {isEditMode: !isLaptop}
    },
    handleSubmit: async (values, {props, setStatus}) => {
        const resp = await props.saveData(values, profileConstants.PERSONAL_INFO_SECTION_ADOBE_KEY);

        if (resp.isError) {
            setStatus({isEditMode: true, isError: true, errorMsg: resp.errorMsg})
        } else {
            setStatus({isEditMode: false});
            props.toggleOtherSectionsEditBtn();
        }
    }
})(PersonalInfoBasicForm);
